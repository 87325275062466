<template>
	<v-layout
		v-if="model && (model.fetched || isFiltering)"
		id="ecm_folder_content"
		align-center
		justify-center
		fill-height
		scroll-y
		:data-dropzone="`${model.id}`"
		@contextmenu.stop="onContextMenu($event, model)"
	>
		<v-flex id="mouse_selection_box_container" ref="foldercontent" scroll-y fill-height pl-1>
			<v-layout v-for="(line, lineIndex) in lines" :key="lineIndex" class="my-1" align-start justify-space-between row mx-2 :data-line-number="lineIndex">
				<v-flex
					v-for="(lineNode, lineNodeIndex) in line.nodes"
					:key="lineNodeIndex"
					:data-node-id="lineNode.isNode ? `${lineNode.nodeId}` : null"
					:data-dropzone="lineNode.nodeId && lineNode.node && lineNode.node.is_folder ? `${lineNode.nodeId}` : null"
					:data-draggable="!model.is_readonly && !model.is_trash && lineNode.isNode ? `${lineNode.nodeId}` : null"
					:class="getNodeClass(lineNode)"

				>
					<FolderContentNode v-if="lineNode.isSubHeader" :ref="getNodeReference(lineNode)" :search="searchedName" :value="lineNode" :display-mode="displayMode" />
						<FolderContentNode
							v-else
							:ref="getNodeReference(lineNode)" :search="searchedName" :value="lineNode" :display-mode="displayMode"
							@click.stop.native="onClick($event, lineNode.node)"
							@dblclick.native="onDoubleClick(lineNode.node)"
							@contextmenu.stop.native="onContextMenu($event, lineNode.node)"
							@mousedown.stop.native
						/>
				</v-flex>
			</v-layout>
		</v-flex>
		<ContextMenu
			v-model="selectedNodes"
			:show-menu="showContextMenu"
			:selected-folder="model"
			:parent-dom-element="$refs.foldercontent"
			:x="contextMenuCoords.x"
			:y="contextMenuCoords.y"
			@showupdated="updateShowContextMenu($event)"
			@close="closeContextMenu()"
		/>
	</v-layout>
</template>
<script>
import { mapState } from 'vuex'

import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'
import MouseAndKeyboardInteractionMixin from '@/mixins/Documents/MouseAndKeyboardInteractionMixin'

export default {
	name: 'FolderContent',
	components: {
		FolderContentNode: () => ({
			component: import('@/components/Documents/Content/FolderContentNode')
		}),
		ContextMenu: () => ({
			component: import('@/components/Documents/ContextMenu/ContextMenu')
		})
	},
	mixins: [DocumentsManagerModuleGuard, MouseAndKeyboardInteractionMixin],
	props: {
		selection: {
			type: Array,
			required: false,
			default: () => []
		},
		value: {
			type: Object,
			required: false,
			default: null,
			validator: function (value) {
				let result = true
				if (!value.is_folder) {
					result = 'Value must be of "folder" type'
				}
				return result
			}
		}
	},
	data: function () {
		return {
			resizeObserver: null,
			currentWidth: null,
			showContextMenu: false,
			contextMenuCoords: {
				x: 0,
				y: 0
			},
			dataLoadingTimeout: null,
			lastSelectedNodeWithoutShiftKey: null
		}
	},
	computed: {
		...mapState({
			displayMode: state => state.documents.displayMode,
			searchedName: state => state.documents.filter.search.filename,
			isGuest: state => state.company.userRoles.isGuest,
			isFiltering: state => state.documents.isFiltering
		}),
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.selectedNodes = []
				this.$emit('input', value)
			}
		},
		selectedNodes: {
			get: function () {
				return this.selection
			},
			set: function (value) {
				this.$emit('selection-changed', value)
			}
		},
		numberOfNodesPerLine: function () {
			let result
			switch (this.displayMode) {
				case 'list':
					result = 1
					break
				case 'mosaic':
				default:
					switch (this.$vuetify.breakpoint.name) {
						case 'xl':
							result = 6
							break
						case 'lg':
							result = 5
							break
						case 'md':
							result = 4
							break
						case 'sm':
							result = 3
							break
						case 'xs':
							result = 3
							break
						default:
							result = 2
							break
					}
					break
			}
			return result
		},
		headerLineHeight: function () {
			return 50
		},
		nodeLineHeight: function () {
			let result
			switch (this.displayMode) {
				case 'list':
					result = 32
					break
				case 'mosaic':
				default:
					result = 110
					break
			}
			return result
		},
		sortParams: function () {
			return this.model?.sort && this.model?.sort.order != '' ? this.appService.compareValues(this.model.sort.field, this.model.sort.order) : null
		},
		documents: function () {
			return this.model.documents
		},
		sortedDocuments: function () {
			return this.sortParams && this.documents ? [...this.documents].sort(this.sortParams) : this.documents
		},
		folders: function () {
			return this.model?.folders
		},
		sortedFolders: function () {
			return this.sortParams && this.folders ? [...this.folders].sort(this.sortParams) : this.folders
		},
		sortedChildren: function () {
			const result = []
			if (this.sortedFolders) {
				result.push(...this.sortedFolders)
			}
			if (this.sortedDocuments) {
				result.push(...this.sortedDocuments)
			}
			return result
		},
		lines: function () {
			const result = []
			let lineIndex = 0
			this.addSubHeaderLine(result, lineIndex)
			this.addFoldersSection(result, lineIndex)
			let nodeNumberOffset = 1
			const folderNodes = []
			if (!this.isFiltering && this.model && !this.model.is_root && this.model.parent) {
				const backToParentNode = { isBackToParentFolder: true, node: this.model.parent, nodeId: this.model.parent.id, nodeNumber: '' }
				folderNodes.push(backToParentNode)
			}
			if (this.sortedFolders?.length > 0) {
				folderNodes.push(
					...this.sortedFolders.map((folder, index) => ({ isNode: true, nodeId: folder.id, nodeNumber: index + nodeNumberOffset, node: folder }))
				)
				nodeNumberOffset = folderNodes.length
			}
			while (folderNodes.length > 0) {
				result.push({ id: lineIndex, nodes: folderNodes.splice(0, this.numberOfNodesPerLine), hasRealNodes: true, size: this.nodeLineHeight })
				lineIndex++
			}
			if (this.model && (!this.model.is_root || this.isFiltering) && this.sortedDocuments?.length > 0) {
				const documentsSubHeaderNode = { isSubHeader: true, text: this.$t('my_documents'), nodeId: '', nodeNumber: '' }
				result.push({ id: lineIndex, nodes: [documentsSubHeaderNode], hasRealNodes: false, size: this.headerLineHeight })
				lineIndex++
				const documentNodes = this.sortedDocuments.map((document, index) => ({
					isNode: true,
					nodeId: document.id,
					nodeNumber: index + nodeNumberOffset,
					node: document
				}))
				while (documentNodes.length > 0) {
					result.push({ id: lineIndex, nodes: documentNodes.splice(0, this.numberOfNodesPerLine), hasRealNodes: true, size: this.nodeLineHeight })
					lineIndex++
				}
			}
			result.forEach(line => {
				while (line.nodes.length < this.numberOfNodesPerLine && !line.nodes.some(node => node.isMessage)) {
					line.nodes.push({ isBlank: true, nodeId: '', nodeNumber: '' })
				}
			})
			return result
		}
	},
	destroyed: function () {
		const componentElement = document.querySelector('#ecm_folder_content')
		if (this.resizeObserver && componentElement) {
			this.resizeObserver.unobserve(componentElement)
		}
		if (this.dataLoadingTimeout) {
			clearTimeout(this.dataLoadingTimeout)
		}
	},
	methods: {
		addSubHeaderLine: function (lines, lineIndex) {
			if (this.isFiltering && this.searchedName) {
				const firstSubHeaderMessage = {
					isMessage: true,
					text: this.$t('ecm.search_results', { name: this.searchedName }),
					nodeId: '',
					nodeNumber: ''
				}
				lines.push({ id: lineIndex, nodes: [firstSubHeaderMessage], hasRealNodes: false, size: this.headerLineHeight })
				lineIndex++
			}
		},
		addFoldersSection: function (lines, lineIndex) {
			if (this.sortedFolders?.length > 0 || !this.model.is_root) {
				const foldersSubHeaderNode = {
					isSubHeader: true,
					text: this.$t('my_folders'),
					nodeId: '',
					nodeNumber: ''
				}
				lines.push({ id: lineIndex, nodes: [foldersSubHeaderNode], hasRealNodes: false, size: this.headerLineHeight })
				lineIndex++
			}
		},
		updateShowContextMenu: function (value) {
			this.showContextMenu = value
		},
		closeContextMenu: function () {
			this.showContextMenu = false
		},
		// eslint-disable-next-line no-unused-vars
		onComponentResize: function ([componentDomElement, ..._othersArgs]) {
			const newWidth = componentDomElement.contentRect.width
			this.contextMenuCoords.x -= (this.currentWidth - newWidth) / 2
			this.currentWidth = newWidth
		},
		getNodeClass: function (lineNode) {
			let result
			if (lineNode.isMessage) {
				result = ['xs12']
			} else {
				result = [`xs${(12 - (12 % this.numberOfNodesPerLine)) / this.numberOfNodesPerLine}`]
			}
			if (lineNode.node && this.selectedNodes.some(node => node && node.id == lineNode.node.id && node.type == lineNode.node.type)) {
				result.push('selection-active')
			} else if (this.model?.is_folder && this.open && this.model?.is_readonly) {
				result.push('selection-forbidden')
			} else {
				result.push('selection-none')
			}
			return result.join(' ')
		},
		getNodeReference: function (node) {
			let result = null
			if (!node.isSubHeader) {
				if (node.is_folder) {
					result = 'foldercontent' + this.displayMode
				} else if (this.model.is_document) {
					result = 'documentcontent' + this.displayMode
				}
			}
			return result
		},
		blurSearchInput: function () {
			this.eventBus.emit(this.events.BLUR_SEARCH_INPUT)
		},
		onContextMenu: function (event, node = null, options = null) {
			event.preventDefault()
			if (node) {
				event.stopPropagation()
			}
			const startWidth = this.$el.clientWidth
			const startHeight = this.$el.clientHeight
			const clickIsNotAdditionnalSelection = (this.OS.isMacOS && !event.metaKey) || ((this.OS.isLinux || this.OS.isWindows) && !event.ctrlKey)
			let openContextMenu = false
			if (!node) {
				if (clickIsNotAdditionnalSelection) {
					this.lastSelectedNodeWithoutShiftKey = null
				}
				openContextMenu = true
			} else if (!options?.is_parent_folder && !this.selectedNodes.includes(node)) {
				if (clickIsNotAdditionnalSelection) {
					this.lastSelectedNodeWithoutShiftKey = node
					this.selectedNodes.splice(0, this.selectedNodes.length, node)
				}
				openContextMenu = true
			} else if (!options?.is_parent_folder && this.selectedNodes.length > 0) {
				openContextMenu = true
			}
			if (openContextMenu) {
				this.openContextMenu(event, { x: this.$el.clientWidth - startWidth, y: this.$el.clientHeight - startHeight })
			}
		},
		openContextMenu: function (event) {
			const coords = {
				x: event.clientX,
				y: event.clientY
			}
			this.showContextMenu = false
			this.contextMenuCoords.x = coords.x
			this.contextMenuCoords.y = coords.y
			this.appEventBus.emit(this.appEvents.CONTEXT_MENU)
			this.$nextTick(() => {
				this.showContextMenu = true
			})
		},
		listNodes: function () {
			const result = []
			if (Array.isArray(this.folders)) {
				result.push(...this.folders)
			}
			if (Array.isArray(this.documents)) {
				result.push(...this.documents)
			}
			return result
		},
		onClick: function (event, node, options) {
			if ((this.OS.isMacOS && event.metaKey) || ((this.OS.isLinux || this.OS.isWindows) && event.ctrlKey)) {
				this.onCtrlClick(node, options)
			} else if (event.shiftKey) {
				this.onShiftClick(node, options)
			} else {
				this.onSingleClick(node, options)
			}
		},
		onDoubleClick: function (document) {
			if (document.is_document) {
				this.eventBus.emit(this.events.preview.OPEN, {
					accountingFirmId: this.accountingFirmId,
					vendorId: this.vendorId,
					document: document,
					folder: this.model
				})
			}
		},
		onCtrlClick: function (node, options) {
			if (!options?.is_parent_folder) {
				this.lastSelectedNodeWithoutShiftKey = node
				const nodeIndex = this.selectedNodes.findIndex(selectedNode => selectedNode.id == node.id)
				if (nodeIndex != -1) {
					this.selectedNodes.splice(nodeIndex, 1)
				} else {
					this.selectedNodes.push(node)
				}
			}
		},
		onShiftClick: function (node) {
			let startIndex = 0;
			let endIndex = 0;

			if (this.lastSelectedNodeWithoutShiftKey) {
				startIndex = this.sortedChildren.findIndex(
					n => n.id === this.lastSelectedNodeWithoutShiftKey.id && n.type === this.lastSelectedNodeWithoutShiftKey.type
				);
			}

			const shiftIndex = this.sortedChildren.findIndex(n => n.id === node.id && n.type === node.type);
			if (shiftIndex === -1) {
				return;
			}

			if (shiftIndex > startIndex) {
				endIndex = shiftIndex;
			} else {
				endIndex = startIndex;
				startIndex = shiftIndex;
			}

			this.selectBetweenElements(this.sortedChildren, startIndex, endIndex);
		},
		onSingleClick: function (node, options) {
			if (node?.is_folder && (!options || options.redirect !== false)) {
				this.model = node
			} else {
				this.lastSelectedNodeWithoutShiftKey = node
				this.selectedNodes = [node]
			}
		},
		selectBetweenElements: function (nodes, startIndex, endIndex) {
			this.selectedNodes = [];

			for (let i = startIndex; i <= endIndex; i++) {
				if (!this.selectedNodes.includes(nodes[i])) {
					this.selectedNodes.push(nodes[i]);
				}
			}
		},
	}
}
</script>
<style>
.truncated-title {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-height: 1.2em;
	max-height: 2.4em;
	word-break: break-word;
}
.mouse-selection-box {
	background-color: rgba(var(--v-primary-transparent), 0.11);
	border-radius: 0.1em;
	border: 1px solid rgba(var(--v-primary-transparent), 0.81);
}
.drop-allowed {
	background-color: rgba(var(--v-primary-transparent), 0.11);
	border-radius: 0.1em;
	border: 1px solid rgba(var(--v-primary-transparent), 0.81);
}
.drop-forbidden {
	background-color: rgba(252, 178, 178, 0.81);
	border-radius: 0.1em;
	border: 1px solid red;
	cursor: 'not-allowed' !important;
}
.selection-none {
	background-color: transparent;
}
.selection-active {
	background-color: rgba(var(--v-primary-transparent), 0.25);
}
.background-forbiden {
	background-color: rgba(252, 178, 178, 0.81);
	cursor: 'not-allowed' !important;
}
.ecm-node {
	user-select: none;
}
</style>
